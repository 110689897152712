import { BrowserRouter, Routes, Route } from "react-router-dom";
import SideBar from "../components/SideBar";
import "../css/pages.css";
import { paths } from "../utils/routes";
import Dashboard from "../pages/index/Dashboard";
import AccountsRouter from "./Accounts";
import Header from "../components/Header";
import ResidentsRouter from "./Residents";
import ServiceCharge from "./ServiceCharge";

function Base(props) {
  return (
    <BrowserRouter>
      <div className="base_container raleway">
        <SideBar />
        <div className="content flex flex-column">
          <Header />
          <div className="routes container">
            <Routes>
              <Route path={paths.base} element={<Dashboard />} />
              <Route path={paths.accounts} element={<AccountsRouter />} />
              <Route path={paths.residents} element={<ResidentsRouter />} />
              <Route path={paths.serviceCharges} element={<ServiceCharge />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Base;
