export const navLinks = Object.freeze([
  {
    title: "Dashboard",
    to: "/",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 2.16667V3.83333H10.5V2.16667H13.8333ZM5.5 2.16667V7.16667H2.16667V2.16667H5.5ZM13.8333 8.83333V13.8333H10.5V8.83333H13.8333ZM5.5 12.1667V13.8333H2.16667V12.1667H5.5ZM15.5 0.5H8.83333V5.5H15.5V0.5ZM7.16667 0.5H0.5V8.83333H7.16667V0.5ZM15.5 7.16667H8.83333V15.5H15.5V7.16667ZM7.16667 10.5H0.5V15.5H7.16667V10.5Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },
  {
    title: "Accounts",
    to: "/accounts",
    renderSvg: () => (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0.666656C4.4 0.666656 0.666664 4.39999 0.666664 8.99999C0.666664 13.6 4.4 17.3333 9 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 9 0.666656ZM4.89166 14.2333C5.25 13.4833 7.43333 12.75 9 12.75C10.5667 12.75 12.7583 13.4833 13.1083 14.2333C11.975 15.1333 10.55 15.6667 9 15.6667C7.45 15.6667 6.025 15.1333 4.89166 14.2333ZM14.3 13.025C13.1083 11.575 10.2167 11.0833 9 11.0833C7.78333 11.0833 4.89166 11.575 3.7 13.025C2.85 11.9083 2.33333 10.5167 2.33333 8.99999C2.33333 5.32499 5.325 2.33332 9 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 10.5167 15.15 11.9083 14.3 13.025ZM9 3.99999C7.38333 3.99999 6.08333 5.29999 6.08333 6.91666C6.08333 8.53332 7.38333 9.83332 9 9.83332C10.6167 9.83332 11.9167 8.53332 11.9167 6.91666C11.9167 5.29999 10.6167 3.99999 9 3.99999ZM9 8.16666C8.30833 8.16666 7.75 7.60832 7.75 6.91666C7.75 6.22499 8.30833 5.66666 9 5.66666C9.69166 5.66666 10.25 6.22499 10.25 6.91666C10.25 7.60832 9.69166 8.16666 9 8.16666Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },
  // {
  //   title: "Residents",
  //   to: "/residents",
  //   renderSvg: () => (
  //     <svg
  //       width="18"
  //       height="18"
  //       viewBox="0 0 18 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M9 0.666656C4.4 0.666656 0.666664 4.39999 0.666664 8.99999C0.666664 13.6 4.4 17.3333 9 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 9 0.666656ZM4.89166 14.2333C5.25 13.4833 7.43333 12.75 9 12.75C10.5667 12.75 12.7583 13.4833 13.1083 14.2333C11.975 15.1333 10.55 15.6667 9 15.6667C7.45 15.6667 6.025 15.1333 4.89166 14.2333ZM14.3 13.025C13.1083 11.575 10.2167 11.0833 9 11.0833C7.78333 11.0833 4.89166 11.575 3.7 13.025C2.85 11.9083 2.33333 10.5167 2.33333 8.99999C2.33333 5.32499 5.325 2.33332 9 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 10.5167 15.15 11.9083 14.3 13.025ZM9 3.99999C7.38333 3.99999 6.08333 5.29999 6.08333 6.91666C6.08333 8.53332 7.38333 9.83332 9 9.83332C10.6167 9.83332 11.9167 8.53332 11.9167 6.91666C11.9167 5.29999 10.6167 3.99999 9 3.99999ZM9 8.16666C8.30833 8.16666 7.75 7.60832 7.75 6.91666C7.75 6.22499 8.30833 5.66666 9 5.66666C9.69166 5.66666 10.25 6.22499 10.25 6.91666C10.25 7.60832 9.69166 8.16666 9 8.16666Z"
  //         fill="#0E79B7"
  //       />
  //     </svg>
  //   ),
  // },
  {
    title: "Houses",
    to: "/facilities",
    renderSvg: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0E79B7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9" />
        <path d="M9 22V12h6v10M2 10.6L12 2l10 8.6" />
      </svg>
    ),
  },
  {
    title: "Finances",
    to: "/finances",
    renderSvg: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0E79B7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <line x1="12" y1="1" x2="12" y2="23"></line>
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
      </svg>
    ),
  },
  {
    title: "Service Charges",
    to: "/service-charges",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
          fill="#0E79B7"
        />
        <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
        <path
          d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
          fill="#0E79B7"
        />
        <path
          d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
          fill="#0E79B7"
        />
        <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
      </svg>
    ),
  },
  {
    title: "Water Bills",
    to: "/water-bills",
    renderSvg: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0E79B7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M3 3v18h18" />
        <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
      </svg>
    ),
  },
  {
    title: "Electricity Bills",
    to: "/electricity-bills",
    renderSvg: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0E79B7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="5" />
        <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
      </svg>
    ),
  },
  // {
  //   title: "Operators",
  //   to: "/operators",
  //   renderSvg: () => (
  //     <svg
  //       width="18"
  //       height="18"
  //       viewBox="0 0 18 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M12.1667 11.85C12.6822 11.85 13.1 11.4321 13.1 10.9167C13.1 10.4012 12.6822 9.98334 12.1667 9.98334C11.6512 9.98334 11.2334 10.4012 11.2334 10.9167C11.2334 11.4321 11.6512 11.85 12.1667 11.85Z"
  //         fill="#0E79B7"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M12.1667 12.5833C11.5583 12.5833 10.3417 12.8833 10.3 13.4833C10.7167 14.075 11.4 14.4583 12.1667 14.4583C12.9333 14.4583 13.6167 14.075 14.0333 13.4833C13.9917 12.8833 12.775 12.5833 12.1667 12.5833Z"
  //         fill="#0E79B7"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M13 7.24167V3.225L6.75 0.5L0.5 3.225V7.31667C0.5 11.1 3.16667 14.6417 6.75 15.5C7.20833 15.3917 7.65 15.2333 8.08333 15.0417C8.98333 16.325 10.475 17.1667 12.1667 17.1667C14.925 17.1667 17.1667 14.925 17.1667 12.1667C17.1667 9.69167 15.3667 7.64167 13 7.24167ZM7.16667 12.1667C7.16667 12.6333 7.23333 13.0917 7.35833 13.5167C7.15833 13.6083 6.95833 13.7 6.75 13.7667C4.10833 12.9333 2.16667 10.2333 2.16667 7.31667V4.31667L6.75 2.31667L11.3333 4.31667V7.24167C8.96667 7.64167 7.16667 9.69167 7.16667 12.1667ZM12.1667 15.5C10.325 15.5 8.83333 14.0083 8.83333 12.1667C8.83333 10.325 10.325 8.83333 12.1667 8.83333C14.0083 8.83333 15.5 10.325 15.5 12.1667C15.5 14.0083 14.0083 15.5 12.1667 15.5Z"
  //         fill="#0E79B7"
  //       />
  //     </svg>
  //   ),
  // },
  {
    title: "Service Requests",
    to: "/service-requests",
    renderSvg: () => (
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3 4.49999L13.8 6.99999H2.2L2.7 4.49999H13.3ZM14.6667 0.333328H1.33333V1.99999H14.6667V0.333328ZM14.6667 2.83333H1.33333L0.5 6.99999V8.66666H1.33333V13.6667H9.66667V8.66666H13V13.6667H14.6667V8.66666H15.5V6.99999L14.6667 2.83333ZM3 12V8.66666H8V12H3Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },
  // {
  //   title: "Orders",
  //   to: "/orders",
  //   renderSvg: () => (
  //     <svg
  //       width="16"
  //       height="14"
  //       viewBox="0 0 16 14"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M13.3 4.49999L13.8 6.99999H2.2L2.7 4.49999H13.3ZM14.6667 0.333328H1.33333V1.99999H14.6667V0.333328ZM14.6667 2.83333H1.33333L0.5 6.99999V8.66666H1.33333V13.6667H9.66667V8.66666H13V13.6667H14.6667V8.66666H15.5V6.99999L14.6667 2.83333ZM3 12V8.66666H8V12H3Z"
  //         fill="#0E79B7"
  //       />
  //     </svg>
  //   ),
  // },
  //   {
  //     title: "iCart Ads",
  //     to: "/icart-ads",
  //     renderSvg: () => (
  //       <svg
  //         width="12"
  //         height="17"
  //         viewBox="0 0 12 17"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M6 10.6667C7.38333 10.6667 8.5 9.55 8.5 8.16666V3.16666C8.5 1.78333 7.38333 0.666664 6 0.666664C4.61667 0.666664 3.5 1.78333 3.5 3.16666V8.16666C3.5 9.55 4.61667 10.6667 6 10.6667ZM5.16667 3.16666C5.16667 2.70833 5.54167 2.33333 6 2.33333C6.45833 2.33333 6.83333 2.70833 6.83333 3.16666V8.16666C6.83333 8.625 6.45833 9 6 9C5.54167 9 5.16667 8.625 5.16667 8.16666V3.16666ZM10.925 8.16666C10.5167 8.16666 10.175 8.46666 10.1083 8.875C9.76667 10.8333 8.05833 12.3333 6 12.3333C3.94167 12.3333 2.23334 10.8333 1.89167 8.875C1.825 8.46666 1.48333 8.16666 1.075 8.16666C0.566668 8.16666 0.166668 8.61666 0.241668 9.11666C0.650001 11.6167 2.65 13.575 5.16667 13.9333V15.6667C5.16667 16.125 5.54167 16.5 6 16.5C6.45833 16.5 6.83333 16.125 6.83333 15.6667V13.9333C9.35 13.575 11.35 11.6167 11.7583 9.11666C11.8417 8.61666 11.4333 8.16666 10.925 8.16666Z"
  //           fill="#0E79B7"
  //         />
  //       </svg>
  //     ),
  //   },
  // {
  //   title: "Concepts and Menus",
  //   to: "/concepts-and-menus",
  //   renderSvg: () => (
  //     <svg
  //       width="16"
  //       height="16"
  //       viewBox="0 0 16 16"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
  //         fill="#0E79B7"
  //       />
  //       <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
  //       <path
  //         d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
  //         fill="#0E79B7"
  //       />
  //       <path
  //         d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
  //         fill="#0E79B7"
  //       />
  //       <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
  //     </svg>
  //   ),
  // },
  {
    title: "Analytics",
    to: "/analytics",
    renderSvg: () => (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6667 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H14.6667C15.5833 15.5 16.3333 14.75 16.3333 13.8333V2.16667C16.3333 1.25 15.5833 0.5 14.6667 0.5ZM14.6667 2.16667V4.66667H2.16667V2.16667H14.6667ZM10.5 13.8333H6.33333V6.33333H10.5V13.8333ZM2.16667 6.33333H4.66667V13.8333H2.16667V6.33333ZM12.1667 13.8333V6.33333H14.6667V13.8333H12.1667Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },
  // {
  //   title: "Wallets",
  //   to: "/wallets",
  //   renderSvg: () => (
  //     <svg
  //       width="16"
  //       height="16"
  //       viewBox="0 0 16 16"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
  //         fill="#0E79B7"
  //       />
  //       <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
  //       <path
  //         d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
  //         fill="#0E79B7"
  //       />
  //       <path
  //         d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
  //         fill="#0E79B7"
  //       />
  //       <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
  //     </svg>
  //   ),
  // },
  {
    title: "Support",
    to: "/support",
    renderSvg: () => (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 8.18333C16.5 3.60833 12.95 0.5 9 0.5C5.09166 0.5 1.5 3.54167 1.5 8.23333C0.999997 8.51667 0.666664 9.05 0.666664 9.66667V11.3333C0.666664 12.25 1.41666 13 2.33333 13H3.16666V7.91667C3.16666 4.69167 5.775 2.08333 9 2.08333C12.225 2.08333 14.8333 4.69167 14.8333 7.91667V13.8333H8.16666V15.5H14.8333C15.75 15.5 16.5 14.75 16.5 13.8333V12.8167C16.9917 12.5583 17.3333 12.05 17.3333 11.45V9.53333C17.3333 8.95 16.9917 8.44167 16.5 8.18333Z"
          fill="#0E79B7"
        />
        <path
          d="M6.5 9.66667C6.96023 9.66667 7.33333 9.29357 7.33333 8.83333C7.33333 8.3731 6.96023 8 6.5 8C6.03976 8 5.66666 8.3731 5.66666 8.83333C5.66666 9.29357 6.03976 9.66667 6.5 9.66667Z"
          fill="#0E79B7"
        />
        <path
          d="M11.5 9.66667C11.9602 9.66667 12.3333 9.29357 12.3333 8.83333C12.3333 8.3731 11.9602 8 11.5 8C11.0398 8 10.6667 8.3731 10.6667 8.83333C10.6667 9.29357 11.0398 9.66667 11.5 9.66667Z"
          fill="#0E79B7"
        />
        <path
          d="M14 7.19167C13.6 4.81667 11.5333 3 9.04167 3C6.51667 3 3.8 5.09167 4.01667 8.375C6.075 7.53333 7.625 5.7 8.06667 3.46667C9.15833 5.65833 11.4 7.16667 14 7.19167Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },
  {
    title: "Configs",
    to: "/configs",
    renderSvg: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0E79B7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="3"></circle>
        <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
      </svg>
    ),
  },
]);
